import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import { initializeApp } from "firebase/app";
require("dotenv").config();
import "@/assets/page-loader.css";
import VueExcelXlsx from 'vue-excel-xlsx';



Vue.use(VueExcelXlsx);

Vue.config.productionTip = false;
Vue.prototype.$miniVariant = false;

const firebaseConfig = {
	apiKey: "AIzaSyCUHBRTSpqgL_tqclDpAzqH_jSDpAOalHg",
	authDomain: "rst-ozo-adminpanel.firebaseapp.com",
	projectId: "rst-ozo-adminpanel",
	storageBucket: "rst-ozo-adminpanel.appspot.com",
	messagingSenderId: "49506918405",
	appId: "1:49506918405:web:3a35723462ba0630107c6c",
	measurementId: "G-WZLTHPQVFT",
};

const app = initializeApp(firebaseConfig);
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		// Если код состояния равен 401 (Unauthorized), выполняем редирект
		if (error.response && error.response.status === 401) {
			store.dispatch("emptyUser");
			router.push('/login'); // Замените '/login' на путь к странице входа
		}

		return Promise.reject(error);
	}
);

axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
	config.headers.appname = "admin.rst.ozo.direct";
	if (store.getters.user.token)
		config.headers.Authorization = `Bearer ${store.getters.user.token}`;
	config.headers["Content-Type"] = "application/json";
	config.headers["Access-Control-Allow-Origin"] = "*";
	config.headers["Access-Control-Allow-Methods"] =
		"GET, POST, PUT, DELETE, OPTIONS";
	config.headers["Access-Control-Allow-Headers"] =
		"Origin, X-Requested-With, Content-Type, Accept, Authorization";
	config.headers["Accept-Language"] =
		"ru";
	return config;
});

Vue.prototype.$http = axios;
Vue.prototype.$store = store;
Vue.prototype.$firebase = app;
Vue.prototype.appname = "Админ панель";

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
