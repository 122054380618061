<!-- :style="{ width: miniVariant ? '65px' : '222px' }" -->
<template>
  <v-app dark>
    <v-navigation-drawer v-if="user.logged" v-model="drawer" :mini-variant="miniVariant"
      :mini-variant-width="drawerWidth" :clipped="clipped" fixed app :width="222">
      <v-list>
        <template v-for="item in items">
          <template>
            <v-list-item :key="item.title" :to="item.to" router exact @click="updatePageTitle(item.title, item.to)">
              <v-list-item-action :style="{ margin: miniVariant ? 'auto' : '20px 15px' }">
                <v-img :src="item.icon" :alt="item.title" class="icon-image-navside" />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <!-- <v-divider
            :key="'divider-' + i"
            :class="['divider-custom', { 'divider-margin': !miniVariant }]"
          /> -->
        </template>
        <div class="root-element">
          <!-- <p v-if="!miniVariant" class="nav-text">Операции</p> -->
          <template v-for="(item, k) in items5">
            <template>
              <v-list-item :style="{ 'border-bottom': miniVariant ? 'none' : '' }" :key="k" :to="item.to" router exact
                @click="updatePageTitle(item.title, item.to)">
                <v-list-item-action :style="{ margin: miniVariant ? 'auto' : '10px 15px' }">
                  <v-img :src="item.icon" :alt="item.title" class="icon-image-navside" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

          </template>
        </div>
        <div class="root-element" v-if="user.roles[0].employeeRole === 'Root'">
          <p v-if="!miniVariant" class="nav-text">Параметры</p>
          <template v-for="(item, k) in items6">
            <template>
              <v-list-item :style="{ 'border-bottom': miniVariant ? 'none' : '' }" :key="k" :to="item.to" router exact
                @click="updatePageTitle(item.title, item.to)">
                <v-list-item-action :style="{ margin: miniVariant ? 'auto' : '10px 15px' }">
                  <v-img :src="item.icon" :alt="item.title" class="icon-image-navside" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

          </template>
        </div>
        <div class="root-element" v-if="user.roles[0].employeeRole === 'Root'">
          <p v-if="!miniVariant" class="nav-text">Приложения</p>
          <template v-for="(item, k) in items3">
            <template>
              <v-list-item :style="{ 'border-bottom': miniVariant ? 'none' : '' }" :key="k" :to="item.to" router exact
                @click="updatePageTitle(item.title, item.to)">
                <v-list-item-action :style="{ margin: miniVariant ? 'auto' : '10px 15px' }">
                  <v-img :src="item.icon" :alt="item.title" class="icon-image-navside" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

          </template>
        </div>
        <div class="root-element2" v-if="user.roles[0].employeeRole === 'Root'">
          <p v-if="!miniVariant" class="nav-text">Ресторан</p>
          <template v-for="(item, k) in items4">
            <template>
              <v-list-item :style="{ 'border-bottom': miniVariant ? 'none' : '' }" :key="k" :to="item.to" router exact
                @click="updatePageTitle(item.title, item.to)">
                <v-list-item-action :style="{ margin: miniVariant ? 'auto' : '10px 15px' }">
                  <v-img :src="item.icon" :alt="item.title" class="icon-image-navside" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

          </template>
        </div>
        <!-- <div v-else>
          <div v-if="miniVariant" class="horizontal-line"></div>
          <p v-if="!miniVariant" class="nav-text">Рестораны</p>
          <template v-for="item in items2">
            <template>
              <v-list-item :key="item.title" :to="item.to" router exact @click="updatePageTitle(item.title, item.to)">
                <v-list-item-action :style="{ margin: miniVariant ? 'auto' : '10px 15px' }">
                  <v-img :src="item.icon" :alt="item.title" class="icon-image-navside" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-divider
            :key="'divider-' + i"
            :class="['divider-custom', { 'divider-margin': !miniVariant }]"
          />
          </template>
        </div> -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="user.logged" :clipped-left="clipped" fixed app>
      <div class="right-panel-nav">
        <!-- <Breadcrumbs :routes="items" /> -->
        <!-- <v-toolbar-title>{{ pageTitle }}</v-toolbar-title> -->
        <v-btn icon @click.stop="miniVariant = !miniVariant" class="burger-menu">
          <img src="./assets/img/ozo_nav_icon.svg" alt="" />
        </v-btn>
        <img src="./assets/img/ozo_logo_header.svg" class="logo-icon" alt="logo-img" />
      </div>
      <div class="right-panel-nav">
        <div v-if="user.currentRest.employeeRole === 'Root' &&
      user.currentRest.restaurant &&
      user.currentRest.restaurant !== null
      " class="current-rest-container">
          <div class="rest-name">
            <p class="rest-title">{{ user.currentRest.restaurant.name }}</p>
            <p class="rest-adress">{{ user.currentRest.restaurant.address }}</p>
          </div>
          <div class="btn-switch" @click="btnSwitchRoot">
            <img src="./assets/img/btn-switch-nav.svg" alt="" />
          </div>
        </div>
        <div v-else-if="user.currentRest && Object.keys(user.currentRest).length > 0
      " class="current-rest-container">
          <div class="rest-name">
            <p class="rest-title">{{ user.currentRest.restaurant.name }}</p>
            <p class="rest-adress">{{ user.currentRest.restaurant.address }}</p>
          </div>
          <div class="btn-switch" @click="btnSwitch">
            <img src="./assets/img/btn-switch-nav.svg" alt="" />
          </div>
        </div>
        <!-- <div v-else class="current-rest-container">
          <p class="rest-title">Ресторан не выбран.</p>
        </div> -->
        <div class="vertical-line"></div>
        <p class="nav-phone" v-if="user.logged">
          {{ user.userInf.name || user.userInf.fullName }}
        </p>
        <p class="nav-phone" v-else>Пользователь не авторизован</p>
        <div class="btn-nav-profile" @click="toggleProfileMenu" ref="profileButton">
          <img src="./assets/img/profile_nav_icon.svg" alt="Custom Image" />
          <div class="profile-menu" v-if="showProfileMenu" ref="profileMenu">
            <!-- <router-link  to="/Profile" class="profile-menu-item-1">Профиль</router-link>
            <div class="divider-modal"></div> -->
            <div class="profile-menu-item-2" @click="handleReturnAndSignOut">
              Выход
            </div>
          </div>
        </div>
      </div>
    </v-app-bar>
    <v-main :class="{
      'login-page': $route.name === 'login',
      'other-pages': $route.name !== 'login',
      'mini-variant-padding': miniVariant,
    }">
      <!-- :style="{
          padding: miniVariant ? '64px 0px 0px 65px' : '64px 0px 0px 222px',
        }" -->
      <div v-if="pageLoader" class="page-loader">
        <div class="d-flex flex-column" style="margin: 0 auto">
          <img src="./assets/img/ozo_login.svg" alt="Loading..." />
          <div class="loader">
            <div class="progress"></div>
          </div>
        </div>
      </div>

      <router-view :key="$route.fullPath" />
    </v-main>
    <!-- <v-navigation-drawer v-model="rightDrawer" :right="right" temporary fixed>
      <v-list>
        <v-list-item @click.native="right = !right">
          <v-list-item-action>
            <v-icon light>mdi-repeat</v-icon>
          </v-list-item-action>
          <v-list-item-title>Switch drawer (click me)</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <!-- <v-footer :absolute="!fixed" app>
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer> -->
  </v-app>
</template>

<script>
// import Breadcrumbs from "@/components/Breadcrumbs.vue"; // Замените путь на корректный
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
/* eslint-disable */
import { getAuth, signOut } from "firebase/auth";
/* eslint-disable */
import router from "@/router";
export default {
  name: "App",
  data() {
    return {
      pageLoader: localStorage.getItem("pageLoaderState") === "true",
      clipped: true,
      drawer: true,
      fixed: false,
      pageTitle: "",
      showProfileMenu: false,
      miniVariantApplied: false,
      pageLoader: false,
      // breadcrumbs: [
      //   { label: "Главная", route: "/" },
      //   { label: "Приложения", route: "/Applications" },
      //   { label: "Рестораны", route: "/Restaurants" },
      //   { label: "Меню", route: "/Menu" },
      //   { label: "Отчеты", route: "/Reports" },
      //   { label: "Персонал", route: "/Staff" },
      //   { label: "Профиль", route: "/Profile" },
      // ],
      items: [
        // {
        //   icon: require("@/assets/img/homeIcon.svg"),
        //   to: "/",
        //   title: "Главная",
        //   breadcrumbTitle: "Главная",
        // },
        // {
        //   icon: require("@/assets/img/profileIcon.svg"),
        //   title: "Профиль",
        //   to: "/Profile",
        //   meta: {
        //     customLabel: "Профиль", // Добавьте это свойство customLabel
        //   },
        //   breadcrumbTitle: "Профиль",
        // },
      ],
      // items2: [
      //   {
      //     icon: require("@/assets/img/staffIcon.svg"),
      //     title: "Персонал",
      //     to: "/Staff",
      //     meta: {
      //       customLabel: "Персонал", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Персонал",
      //   },
      // ],
      // items3: [
      //   {
      //     icon: require("@/assets/img/versionIcon.png"),
      //     title: "Версии",
      //     to: "/AppVersion",
      //     meta: {
      //       customLabel: "Версии", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Версии",
      //   },
      //   {
      //     icon: require("@/assets/img/firebaseIcon.png"),
      //     title: "Firebase",
      //     to: "/Firebase",
      //     meta: {
      //       customLabel: "Firebase", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Firebase",
      //   },

      //   {
      //     icon: require("@/assets/img/statsIcon.png"),
      //     title: "Статистика",
      //     to: "/Stats",
      //     meta: {
      //       customLabel: "Статистика", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Статистика",
      //   },
      //   {
      //     icon: require("@/assets/img/GuestMembersIcon.png"),
      //     title: "Юридические Лица",
      //     to: "/AccountList",
      //     meta: {
      //       customLabel: "Юридические Лица", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Юридические Лица",
      //   },
      // ],
      // items4: [
      //   {
      //     icon: require("@/assets/img/restIcon.svg"),
      //     title: "Ресторан",
      //     to: "/RestaurantRoot",
      //     meta: {
      //       customLabel: "Ресторан",
      //     },
      //     breadcrumbTitle: "Ресторан",
      //   },
      //   {
      //     icon: require("@/assets/img/menuIcon.svg"),
      //     title: "Меню",
      //     to: "/Menu",
      //     meta: {
      //       customLabel: "Меню", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Меню",
      //   },
      //   {
      //     icon: require("@/assets/img/staffIcon.svg"),
      //     title: "Персонал",
      //     to: "/Staff",
      //     meta: {
      //       customLabel: "Персонал", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Персонал",
      //   },
      // ],
      items5: [
        // {
        //   icon: require("@/assets/img/GuestMembersIcon.png"),
        //   title: "Гости",
        //   to: "/GuestMembers",
        //   meta: {
        //     customLabel: "Гости",
        //   },
        //   breadcrumbTitle: "Гости",
        // },
        // {
        //   icon: require("@/assets/img/loyalLogo.svg"),
        //   title: "Программа..",
        //   to: "/LoyaltyProgramOperation",
        //   meta: {
        //     customLabel: "Программа..", // Добавьте это свойство customLabel
        //   },
        //   breadcrumbTitle: "Программа..",
        // },
        // {
        //   icon: require("@/assets/img/couponLogo.svg"),
        //   title: "Купоны",
        //   to: "/CouponOperation",
        //   meta: {
        //     customLabel: "Купоны", // Добавьте это свойство customLabel
        //   },
        //   breadcrumbTitle: "Купоны",
        // },
        // {
        //   icon: require("@/assets/img/deliveryLogo.svg"),
        //   title: "Доставка",
        //   to: "/Delivery",
        //   meta: {
        //     customLabel: "Доставка", // Добавьте это свойство customLabel
        //   },
        //   breadcrumbTitle: "Доставка",
        // },
        // {
        //   icon: require("@/assets/img/paymentLogo.svg"),
        //   title: "Кошелек",
        //   to: "/Payment",
        //   meta: {
        //     customLabel: "Кошелек", // Добавьте это свойство customLabel
        //   },
        //   breadcrumbTitle: "Кошелек",
        // },
        {
          icon: require("@/assets/img/analytics.svg"),
          title: "Аналитика",
          to: "/Analytics",
          meta: {
            customLabel: "Аналитика", // Добавьте это свойство customLabel
          },
          breadcrumbTitle: "Аналитика",
        },
        {
          icon: require("@/assets/img/GuestMembersIcon.png"),
          title: "Гости",
          to: "/GuestDashboard",
          meta: {
            customLabel: "Гости", // Добавьте это свойство customLabel
          },
          breadcrumbTitle: "Гости",
        },
        {
          icon: require("@/assets/img/OrderDashboard.png"),
          title: "Заказы",
          to: "/OrderPage",
          meta: {
            customLabel: "Заказы", // Добавьте это свойство customLabel
          },
          breadcrumbTitle: "Заказы",
        },
        {
          icon: require("@/assets/img/ReviewDash.png"),
          title: "Отзывы",
          to: "/ReviewsPage",
          meta: {
            customLabel: "Отзывы", // Добавьте это свойство customLabel
          },
          breadcrumbTitle: "Отзывы",
        },
      ],
      // items6: [
      //   {
      //     icon: require("@/assets/img/restIcon.png"),
      //     title: "Рестораны",
      //     to: "/RestaurantRoot",
      //     meta: {
      //       customLabel: "Рестораны",
      //     },
      //     breadcrumbTitle: "Рестораны",
      //   },
      //   {
      //     icon: require("@/assets/img/menuIcon.png"),
      //     title: "Меню",
      //     to: "/Menu",
      //     meta: {
      //       customLabel: "Меню", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Меню",
      //   },
      //   {
      //     icon: require("@/assets/img/loyalLogo.svg"),
      //     title: "Программа...",
      //     to: "/LoyaltyProgram",
      //     meta: {
      //       customLabel: "Программа..", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Программа..",
      //   },
      //   {
      //     icon: require("@/assets/img/couponLogo.svg"),
      //     title: "Купон",
      //     to: "/Coupon",
      //     meta: {
      //       customLabel: "Купон", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Купон",
      //   },
      //   {
      //     icon: require("@/assets/img/deliveryLogo.svg"),
      //     title: "Доставка",
      //     to: "/DeliveryOperation",
      //     meta: {
      //       customLabel: "Доставка", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Доставка",
      //   },
      //   {
      //     icon: require("@/assets/img/appIcon.png"),
      //     title: "Приложения",
      //     to: "/Guest",
      //     meta: {
      //       customLabel: "Приложения", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Приложения",
      //   },
      //   {
      //     icon: require("@/assets/img/GuestMembersIcon.png"),
      //     title: "Пользователи...",
      //     to: "/UserMembers",
      //     meta: {
      //       customLabel: "Пользователи...", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Пользователи...",
      //   },
      //   {
      //     icon: require("@/assets/img/GuestMembersIcon.png"),
      //     title: "Пользователи...",
      //     to: "/UserMembersApp",
      //     meta: {
      //       customLabel: "Пользователи...", // Добавьте это свойство customLabel
      //     },
      //     breadcrumbTitle: "Пользователи...",
      //   },
      // ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "",
      drawerWidth: 57,
    };
  },
  watch: {
    miniVariant(newVal) {
      if (!newVal) {
        this.miniVariantApplied = true;
      }
    },
  },
  methods: {
    ...mapActions(["emptyUser"]),
    async handleReturnAndSignOut() {
      const auth = getAuth();
      const user = auth.currentUser;
      this.pageLoader = true;

      try {
        // Выполняем GET запрос на ваш API
        await this.$axios.get("/api/Auth/Logout");

        // Если пользователь существует, разлогиниваем его
        if (user) {
          await signOut(auth);
        }
      } catch (error) {
        console.error("Ошибка при разлогине пользователя:", error);
      }

      // Очищаем данные пользователя в Vuex store
      this.$store.dispatch("emptyUser");

      try {
        // Перенаправляем на страницу логина
        this.$router.push({ name: "Login" });
        setTimeout(() => {
          this.pageLoader = false;
        }, 1500);
      } catch (error) {
        console.error("Ошибка при перенаправлении пользователя:", error);
      }
    },
    updatePageTitle(title) {
      this.pageTitle = title;

      // Создаем новый объект для текущей крошки и добавляем его в хлебные крошки
      // const currentCrumb = { label: title, route: route };
      // this.breadcrumbs.push(currentCrumb);
    },

    toggleProfileMenu() {
      this.showProfileMenu = !this.showProfileMenu;
    },
    handleClickOutside(event) {
      const profileButton = this.$refs.profileButton;
      const profileMenu = this.$refs.profileMenu;

      if (
        profileButton &&
        profileMenu &&
        !profileButton.contains(event.target) &&
        !profileMenu.contains(event.target)
      ) {
        this.showProfileMenu = false;
      }
    },

    btnSwitch() {
      this.pageLoader = true;
      this.$store.commit("setCurrentRest", {});
      // Записываем состояние pageLoader в Local Storage перед перезагрузкой
      localStorage.setItem("pageLoaderState", "true");

      // Загрузка нового маршрута
      router.push("/RestaurantChoose").then(() => {
        // Устанавливаем задержку перед перезагрузкой страницы
        setTimeout(() => {
          // Удаляем сохраненное состояние pageLoader из Local Storage
          localStorage.removeItem("pageLoaderState");

          // Перезагружаем страницу после загрузки нового маршрута
          window.location.reload(true);
        }, 1500);
      });
    },
    btnSwitchRoot() {
      if (router.currentRoute.name === "RestaurantRoot") {
        return;
      } else {
        router.push("/RestaurantRoot");
        this.$store.commit("setRootRestaurant", {});
      }
    },
  },

  mounted() {
    window.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
  },
  created() {
    let expireDate = new Date(this.user.expireDate);
    let userRoles = this.user.roles;
    let currentDate = new Date();
    if (expireDate < currentDate) {
      // this.$store.commit("setLogged", false);  
      // this.$store.dispatch("emptyUser");
    }
  },

  components: {
    // Breadcrumbs,
  },
  computed: {
    routes() {
      return this.$router.options.routes;
    },
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>


<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
  width: 5px;
  /* Ширина скроллбара */
}

::v-deep .v-navigation-drawer__content::-webkit-scrollbar-track {
  background-color: rgb(50, 60, 77);
  /* Цвет фона скроллбара */
}

::v-deep .v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Цвет самого скроллбара */
  border-radius: 10px;
  /* Скругление углов скроллбара */
}

/* Цвет полоски скроллбара */
.current-rest-container {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.rest-title {
  color: var(--04, #eaeaea);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
  text-align: end;
}

.rest-adress {
  color: var(--04, #eaeaea);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  text-align: end;
}

.btn-switch {
  width: 33px;
  height: 30px;
  flex-shrink: 0;
  margin-left: 18px;
  cursor: pointer;
}

.horizontal-line {
  background-color: red;
  height: 1px;
  width: 100%;
  /* Ширина линии равна 100% родительского контейнера */
}

.nav-text {
  color: white;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 11px;
  padding-left: 15px;
  margin-bottom: 10px;
}

/* ::v-deep .v-list-item__action {
  align-self: center;
  margin: 12px 21px;
} */
.logo-icon {
  margin-left: 32px;
  margin-right: 37px;
}

.admin-panel {
  color: var(--background, #f2f2f6);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

::v-deep .theme--light.v-app-bar.v-toolbar.v-sheet {
  background: rgb(50, 60, 77);
}

.logout-btn {
  display: inline-flex;
  padding: 14px 34px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  border: 1px solid var(--white, #fff);
  background: var(--01, #333);
  margin-top: 14px;
  margin-left: 37.5px;
  cursor: pointer;
}

.centered-container {
  display: flex;
  padding: 14px 14px;
  justify-content: center;
  align-items: center;
  /* gap: 12px; */
  border-radius: 50px;
  border: 1px solid #fff;
  border: 1px solid var(--white, #fff);
  background: #333;
  background: var(--01, #333);
  /* margin: auto; */
  width: fit-content;
  /* margin: 14px auto; */
  margin-top: 14px;
  margin-left: auto;
  margin-bottom: 0px;
  margin-right: auto;
  cursor: pointer;
}

.logout-container {
  position: absolute;
  /* или "fixed" в зависимости от ваших потребностей */
  bottom: 21px;
  /* Расстояние от нижней границы родительского контейнера */
  /* Другие стили для вашего контейнера */
  width: 100%;
}

.logout-text {
  margin-bottom: 0px;
  color: var(--white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-right: 37px;
}

.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  /* Расширяем на всю ширину родительского контейнера */
  margin: 5px 0;
  /* Подстроить под ваши требования */
}

.profile-menu {
  position: absolute;
  bottom: -50px;
  left: -65px;
  width: 100px;
  /* height: 95px; */
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* .profile-menu {
  старый стиль который надо вернуть , верхний удалить и так же вернуть старый profile-menu-item-2
  position: absolute;
  bottom: -110px;
  left: -180px;
  width: 213px;
  height: 95px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
} */

.profile-menu-item-1 {
  padding-top: 17px;
  padding-left: 23px;
  padding-bottom: 5px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
}

/* .profile-menu-item-2 {
  старый надо вернуть
  padding-top: 8px;
  padding-left: 23px;
  
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
} */

.profile-menu-item-2 {
  padding-top: 8px;
  padding-left: 23px;
  padding-bottom: 8px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
}

.btn-nav-profile {
  height: 30px !important;
  width: 30px !important;
  padding: 0px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-radius: 32px;
  background: var(--gray-lighter,
      linear-gradient(0deg,
        rgba(255, 255, 255, 0.75) 0%,
        rgba(255, 255, 255, 0.75) 100%),
      #c2c9d1);
}

.nav-phone {
  margin-bottom: 0px !important;
  color: var(--background, #f2f2f6);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 27px;
}

.right-panel-nav {
  display: flex;
  align-items: center;
}

::v-deep .v-toolbar__content,
.v-toolbar__extension {
  align-items: center;
  display: flex;
  position: relative;
  z-index: 0;
  flex-direction: row;
  justify-content: space-between;
  /* height: 78px !important; */
}

.theme--light.v-divider {
  border-color: #585858;
}

::v-deep .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  border-bottom: 0.5px solid rgb(152, 162, 178);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
}

::v-deep .v-toolbar__title {
  color: var(--background, #f2f2f6);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* ::v-deep .mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
} */
::v-deep .theme--light.v-navigation-drawer {
  background: rgb(50, 60, 77);
  box-shadow: 0px 4px 13px 0px rgba(144, 167, 188, 0.25);
}

.divider-margin {
  margin-left: 20px;
  /* Добавьте необходимый вам отступ */
}

::v-deep .v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 16px;
}

::v-deep .v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding: 0px;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
}

::v-deep .v-list-item {
  border-bottom: 0.5px solid rgb(152, 162, 178);
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding-left: 0px;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
}

/* ::v-deep .v-list-item:first-child
 вернуть когда будет подпукнты меню
 {
  border-bottom: 1px solid rgb(152, 162, 178);
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding-left: 0px;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
} */

::v-deep .v-list-item__action,
.v-list-item__avatar,
.v-list-item__icon {
  display: inline-flex;
  min-width: 19px;
}

.icon-image-navside {
  width: 19px;
  /* Ширина изображения */
  height: 19px;
  /* Высота изображения */
}

::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: rgb(255, 255, 255);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::v-deep .v-list .v-list-item--active {
  color: white;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
  border-right: 5px solid #00b800;
  background-color: rgba(96, 108, 128, 0.5);
  ;
  /* Толщина, стиль и цвет левой границы */
}

::v-deep .v-list-item--link:before {
  background-color: aliceblue !important;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

::v-deep .v-list {
  display: block;
  padding: 0px;
  position: static;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: padding 0.3s;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.mini-variant-padding {
  padding: 64px 0px 0px 0px;
}

.login-page {
  padding: 64px 0px 0px 222px;
}

.other-pages {
  padding: 64px 0px 0px 222px;
}

::v-deep .theme--light.v-application {
  background-color: #F8F8F8 !important;
  color: rgba(0, 0, 0, 0.87);
}

html,
body {
  background-color: #F8F8F8;
  /* Цвет фона */
}

/* Пример изменения темы */
.theme--light.v-application {
  background-color: #F8F8F8;
  /* Цвет фона */
}

/* Или для темы dark */
.theme--dark.v-application {
  background-color: #F8F8F8;
  /* Цвет фона */
}

/* * {
  outline: 4px solid green !important;
} */

body {
  font-size: 20px;
  /* 1em будет равно 16px */
}
</style>
