import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import axios from "axios";
import { getAuth } from "firebase/auth";
import router from "../router";
require("dotenv").config();

const localStorage = new VuexPersist({
	key: "vuex",
	storage: window.localStorage,
	reducer: (state) => ({
		user: state.user,
	}),
});

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [localStorage.plugin],
	state: {
		user: {
			userInf: {},
			roles: [],
			logged: false,
			token: null,
			expireDate: null,
			currentRest: {},
		},
	},
	getters: {
		user(state) {
			return state.user;
		},
	},
	mutations: {
		setToken(state, token) {
			state.user.token = token;
			console.log(token);
		},
		setRootRestaurant(state, restaurant) {
			// Мутация для установки ресторана текущему пользователю
			state.user.currentRest.restaurant = restaurant;
		},
		setData(state, userInf) {
			state.user.userInf = userInf;
		},
		setLogged(state, logged) {
			state.user.logged = logged;
		},
		setRoles(state, roles) {
			state.user.roles = roles;
			console.log(state.user.roles);
		},
		setExpireDate(state, expireDate) {
			state.user.expireDate = expireDate;
		},
		setCurrentRest(state, currentRest) {
			state.user.currentRest = currentRest;
		},
	},
	actions: {
		async fetchUser({ commit }) {
			try {
				const auth = getAuth();
				const user = auth.currentUser;

				if (!user) {
					// Если пользователь не вошел, не делаем ничего
					return;
				}

				const idToken = await user.getIdToken();

				// Получаем токен и выполняем запросы на сервер
				const applicationToken = await axios.post(
					process.env.VUE_APP_API_HOST + "/api/Auth/GetApplicationToken",
					{
						VerificationCode: idToken,
						appName: "admin.rst.ozo.direct",
					}
				);

				commit("setToken", applicationToken.data.accessToken);

				const userToken = await axios.post(
					process.env.VUE_APP_API_HOST + "/api/Auth/GetUserToken",
					{
						Authorization: "Bearer " + applicationToken.data.accessToken,
						IdToken: idToken,
						headers: {
							Authorization: "Bearer " + applicationToken.data.accessToken,
							idToken: idToken,
						},
					}
				);

				commit("setToken", userToken.data.accessToken);
				commit("setData", userToken.data.accessToken);
				commit("setExpireDate", userToken.data.accessTokenExpireDateUtc);
				// commit("setLogged", true);

				const employee = await axios.get(
					process.env.VUE_APP_API_HOST + "/api/Auth/WhoAmI",
					{
						headers: {
							Authorization: "Bearer " + userToken.data.accessToken,
						},
					}
				);

				const roles = employee.data;
				commit("setRoles", roles);

				if (roles.length === 0) {
					if (router.currentRoute.name !== "ErrorPage") {
						router.push({ name: "ErrorPage" });
						commit("setLogged", true);
					}
				} else {
					if (roles[0].employeeRole === "Root") {
						const employeeToken = await axios.get(
							process.env.VUE_APP_API_HOST +
								`/api/Auth/GetEmployeeToken?employeeId=${employee.data[0].id}`
						);

						commit("setToken", employeeToken.data.accessToken);
						commit(
							"setExpireDate",
							employeeToken.data.accessTokenExpireDateUtc
						);
						commit("setLogged", true);
						commit("setCurrentRest", employee.data[0]);
						commit("setRootRestaurant", {});

						const profile = await axios.get(
							process.env.VUE_APP_API_HOST + `/api/Contact/GetMyProfile`
						);

						commit("setData", profile.data);
						router.push({ name: "RestaurantRoot" });
					} else {
						const profile = await axios.get(
							process.env.VUE_APP_API_HOST + "/api/Contact/GetMyProfile"
						);

						commit("setData", profile.data);
						router.push("/RestaurantChoose");
						// .then(() => {
						//     window.location.reload(true); // Для обновления страницы при переходе
						// });
						// window.location.reload(true);
					}
				}
			} catch (error) {
				console.error("Ошибка при получении пользователя:", error);
				// Обработка ошибки, например, отображение сообщения пользователю
			}
		},
		renewToken({ commit }, user) {
			axios
				.get(process.env.VUE_APP_API_HOST + "/api/Auth/RefreshToken", {
					headers: {
						Authorization: "Bearer" + user.token,
					},
				})
				.then((response) => {
					commit("setToken", response.data.accessToken);
					commit("setExpireDate", response.data.accessTokenExpireDateUtc);
				})
				.catch(() => {});
		},
		emptyUser({ commit }) {
			commit("setToken", null);
			commit("setData", {});
			commit("setLogged", false);
			commit("setRoles", []);
			commit("setCurrentRest", {});
			commit("setExpireDate", null);
			// localStorage.clear();
		},
	},
	modules: {},
});
