import Vue from "vue";
import Vuetify from "vuetify/dist/vuetify.js";
import "vuetify/dist/vuetify.min.css";
import ru from "vuetify/lib/locale/ru";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#323c4d",
        secondary: "#FFFFFF",
        accent: "#323c4d",
        error: "#E61D2B",
        info: "#979797",
        warning: "#FCB62E",
        background: "#F9F9F9",
      },
    },
  },
  lang: {
    locales: { ru },
    current: "ru",
  },
});

export default vuetify;
